import { definePropertySafe } from 'Utilities/definePropertySafe'

try {
  const _dp = Object.definePropertySafe
  _dp(Object, 'defineProperty', {
    value: function () {
      try {
        return _dp.apply(this, arguments)
      } catch (e) {
        console.warn(e)
      }
    }
  })
} catch (_e) {
  console.log('failed to override Object.definePropertySafe')
  // pass
}

Object.definePropertySafeSafe = definePropertySafe

// import('./helpers/widgetDetector').then(({ default: widgetDetector }) => {
//   widgetDetector.checkWidgetStatus()
// })

function loadTriplabot() {
  import(/* webpackChunkName: "triplabot" */ './triplabot').then(({ default: bootstrap }) => {
    bootstrap()
  })
}

function loadChatbotByTenants() {
  const scripts = [...document.querySelectorAll('script[data-triplabot-code]')]
  const ourScript = scripts.find(script => new URL(script.src).searchParams.get('code')) || scripts[0]
  if (!ourScript) return

  const hotelCode = ourScript.getAttribute('data-triplabot-code')
  const vipFacilities = process.env.VIP_FACILITIES?.split(',') || []
  const isVIP = vipFacilities.includes(hotelCode)

  const url = new URL(ourScript.src)
  const hotelCodeInScriptSrc = url.searchParams.get('code')

  if (hotelCodeInScriptSrc || !isVIP) {
    loadTriplabot()
  } else {
    const clonedScript = document.createElement('script')
    ourScript.getAttributeNames().forEach(name => {
      clonedScript.setAttribute(name, ourScript.getAttribute(name))
    })
    clonedScript.src = clonedScript.src + `?code=${ourScript.getAttribute('data-triplabot-code')}`
    document.body.appendChild(clonedScript)
  }
}

if (window.top === window.self) {
  loadChatbotByTenants()
} else {
  loadTriplabot()
}
